import { Modal } from 'antd';
import React, { useEffect } from 'react';
import { OPERATIONS } from '../../../common/constants';

export default function ConfirmEmptyValuesModal({
  visible,
  setVisible,
  setTwoFaModalVisible,
  setActionId,
  setActionKey,
  selectedItemName,
  nullValueKeys,
  actionKey,
  setExportEnvKeysModalOpen,
  setDuplicateValuesModalOpen,
  duplicateKeys,
}) {
  const handleOk = () => {
    if (duplicateKeys?.length > 0) {
      setVisible(false);
      setDuplicateValuesModalOpen(true);
    } else {
      setVisible(false);
      if (actionKey === OPERATIONS?.VERIFY_ENV) {
        setTwoFaModalVisible(true);
      } else {
        setExportEnvKeysModalOpen(true);
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event?.key === 'Enter' && visible) {
        event?.preventDefault();
        handleOk();
      }
    };

    // eslint-disable-next-line no-undef
    document?.addEventListener('keydown', handleKeyDown);
    return () => {
      // eslint-disable-next-line no-undef
      document?.removeEventListener('keydown', handleKeyDown);
    };
  }, [visible]);

  return (
    <Modal
      open={visible}
      centered
      title={
        <div className="d-flex" title={selectedItemName}>
          Confirm empty values before{' '}
          {actionKey === OPERATIONS?.VERIFY_ENV ? 'verify' : 'export'}
        </div>
      }
      onOk={() => handleOk()}
      onCancel={() => {
        setActionKey(null);
        setVisible(false);
        setActionId(null);
      }}
      okText="Confirm"
    >
      Are you sure you want to{' '}
      {actionKey === OPERATIONS?.VERIFY_ENV ? 'verify' : 'export'} '
      {selectedItemName}' ENV as it has empty values of keys:{' '}
      {nullValueKeys?.join(', ')}
    </Modal>
  );
}
