import { useMutation } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import useRouter from '../../hooks/useRouter';
import { REFRESH_TOKEN } from './graphql/Mutations';

const RefreshToken = () => {
  const { initializeAuth, getRefreshToken } = useContext(AppContext);
  const {
    navigate,
    location: { state },
  } = useRouter();
  const refreshToken = getRefreshToken();
  function successCallback(accessToken, userData, isTwoFaEnabled) {
    initializeAuth(accessToken, userData, refreshToken, isTwoFaEnabled);
  }

  const [refresh, { loading }] = useMutation(REFRESH_TOKEN, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      const accessToken = response?.newAccessToken?.token;
      const userData = response?.newAccessToken?.user;
      const isTwoFaEnabled = response?.newAccessToken?.user?.isTwoFaEnabled;
      successCallback(accessToken, userData, isTwoFaEnabled);
      if (state?.from) {
        navigate(state?.from, { replace: true });
      } else {
        navigate(-1);
      }
    },
    onError() {
      navigate(ROUTES?.LOGOUT, { replace: true });
    },
  });

  useEffect(() => {
    if (refreshToken)
      refresh({
        variables: {
          refreshToken,
        },
      });
  }, []);

  if (loading) return <LoaderComponent />;

  return null;
};

export default RefreshToken;
