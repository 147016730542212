import { Modal } from 'antd';
import React, { useEffect } from 'react';
import { OPERATIONS } from '../../../common/constants';

export default function DeleteEnvModal({
  visible,
  setVisible,
  setTwoFAModalOpen,
  setActionKey,
  selectedItemName,
}) {
  const handleOk = () => {
    setVisible(false);
    setActionKey(OPERATIONS.DELETE_ENV);
    setTwoFAModalOpen(true);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event?.key === 'Enter' && visible) {
        event?.preventDefault();
        handleOk();
      }
    };

    // eslint-disable-next-line no-undef
    document?.addEventListener('keydown', handleKeyDown);
    return () => {
      // eslint-disable-next-line no-undef
      document?.removeEventListener('keydown', handleKeyDown);
    };
  }, [visible]);

  return (
    <Modal
      open={visible}
      centered
      title={
        <div className="d-flex" title={selectedItemName}>
          Delete '
          <div className="text-ellipsis modal-title"> {selectedItemName}</div>'
          ENV
        </div>
      }
      onOk={() => handleOk()}
      onCancel={() => setVisible(false)}
      okText="Confirm"
    >
      Are you sure you want to permanently delete this environment? This action
      cannot be undone, and all associated key/s will be lost.
    </Modal>
  );
}
