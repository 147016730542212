import { Button, Modal } from 'antd';
import React from 'react';

export default function ExportEnvKeysModal({
  visible,
  setVisible,
  setTwoFAModalOpen,
  selectedItemName,
}) {
  return (
    <Modal
      open={visible}
      centered
      title={
        <div className="d-flex" title={selectedItemName}>
          Export '
          <div className="text-ellipsis modal-title"> {selectedItemName}</div>'
          ENV
        </div>
      }
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <div className="d-flex justify-center gap-16">
        <Button disabled size="large">
          AWS
        </Button>
        <Button
          size="large"
          onClick={() => {
            setVisible(false);
            setTwoFAModalOpen(true);
          }}
        >
          .env
        </Button>
      </div>
    </Modal>
  );
}
