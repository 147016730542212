import { ArrowLeftOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Card, Descriptions, Table } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import {
  defaultLogTimeFormat,
  LIMIT,
  LOGS_ACTION,
  REPOSITORY_ID,
  ROUTES,
} from '../../../common/constants';
import useRouter from '../../../hooks/useRouter';
import '../dashboard.less';
import { GET_HISTORY } from '../graphql/Queries';

dayjs.extend(utc);

export default function History() {
  const {
    setRepositoryId,
    state: { repositoryId },
  } = useContext(AppContext);
  const {
    navigate,
    location: { state },
  } = useRouter();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(LIMIT);
  const [filters, setFilters] = useState({
    skip: 0,
    limit: LIMIT,
  });

  const [getHistory, { data, loading }] = useLazyQuery(GET_HISTORY, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    getHistory({
      variables: {
        data: {
          // eslint-disable-next-line no-undef
          repositoryId: repositoryId ?? localStorage?.getItem(REPOSITORY_ID),
          ...filters,
        },
      },
    });
  }, [repositoryId, filters]);

  const columns = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (value) =>
        dayjs?.utc(value)?.local()?.format(defaultLogTimeFormat),
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (_, record) => (
        <>
          <p className="m-0">
            {record?.firstName ?? '-'} {record?.lastName ?? '-'}
          </p>
          <span>[{record?.email ?? '-'}]</span>
        </>
      ),
    },
    {
      title: 'Entity',
      dataIndex: 'entity',
      key: 'entity',
    },
    {
      title: 'Operation',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const onChange = (pagination) => {
    setCurrentPage(pagination?.current);
    setPageSize(pagination?.pageSize);
    setFilters({
      ...filters,
      skip: currentPage ? (pagination?.current - 1) * pagination?.pageSize : 0,
      limit: currentPage ? pagination?.pageSize : 50,
    });
  };

  const getRecords = (action, record, recordType) => {
    const recordData = record?.[recordType];

    if (!recordData?.length) {
      return <span>No data available</span>;
    }

    const renderEnvironment = (environments) =>
      environments?.map((env) => env?.environment)?.join(', ');

    switch (action) {
      case LOGS_ACTION.ADDED:
        return (
          <span>
            {record?.entity !== 'Environment'
              ? `${
                  recordData?.[0]?.keyName
                } key added for ENV's: ${renderEnvironment(
                  recordData?.[0]?.environment,
                )}`
              : recordData?.join(', ')}
          </span>
        );

      case LOGS_ACTION.UPDATED:
        return (
          <>
            <p className="m-0">ENV : {recordData?.[0]?.environment ?? '-'}</p>
            <span>
              {recordData?.[0]?.keyName} : {recordData?.[0]?.value}
            </span>
          </>
        );

      case LOGS_ACTION.DELETED:
        return (
          <span>
            {record?.entity !== 'Environment'
              ? `${
                  recordData?.[0]?.keyName
                } key deleted from ENV: ${renderEnvironment(
                  recordData?.[0]?.environment,
                )}`
              : recordData.join(', ')}
          </span>
        );

      case LOGS_ACTION.DUPLICATED:
        return (
          <>
            {recordData?.map((env) => (
              <>
                <p className="m-0">ENV : {env?.environment ?? '-'}</p>
                <span>
                  {env?.keyName} : {env?.value === '' ? '---' : env?.value}
                </span>
              </>
            ))}
          </>
        );

      case LOGS_ACTION.VERIFIED:
        return (
          <span>
            Verified: {recordData?.[0]?.isVerified === 'true' ? 'Yes' : 'No'}
          </span>
        );

      default:
        return <span> - </span>;
    }
  };

  return (
    <div className="table-card-page">
      <Card
        title={
          <>
            <div className="d-flex gap-16 align-center">
              <Button
                type="text"
                shape="square"
                onClick={() => {
                  setRepositoryId(null);
                  navigate(ROUTES.MAIN, {
                    state: {
                      projectData: state?.projectData,
                      repositoryId: state?.repositoryId,
                    },
                  });
                }}
                icon={<ArrowLeftOutlined />}
              />
              <h2 className="m-0">History</h2>
            </div>
          </>
        }
        className="ant-body-scroll"
      >
        <div className="d-flex flex-vertical gap-16">
          <div className="history-table">
            <Table
              columns={columns}
              size="small"
              rowKey={(record) => record?.id}
              dataSource={data?.viewHistory?.data}
              onChange={onChange}
              loading={loading}
              pagination={{
                current: currentPage,
                total: data?.viewHistory?.count,
                pageSize,
              }}
              scroll={{ x: 'auto', y: 'calc(100vh - 240px)' }}
              expandable={{
                expandedRowRender: (record) => (
                  <Descriptions bordered size="small">
                    <Descriptions.Item span={3} label="Description">
                      {record?.log}
                    </Descriptions.Item>
                    {record?.action !== LOGS_ACTION?.EXPORTED && (
                      <>
                        <Descriptions.Item label="Old Data">
                          {getRecords(record?.action, record, 'oldData')}
                        </Descriptions.Item>
                        <Descriptions.Item label="New Data">
                          {getRecords(record?.action, record, 'newData')}
                        </Descriptions.Item>
                      </>
                    )}
                  </Descriptions>
                ),
                rowExpandable: (record) => record?.name !== 'Not Expandable',
              }}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}
