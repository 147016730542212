import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';

export default function ViewKeyValueModal({
  visible,
  setVisible,
  privateKeyValueform,
  updateEnvKeyValue,
  actionId,
  setActionId,
}) {
  const [isEditable, setIsEditable] = useState(false);

  const onFinish = (values) => {
    updateEnvKeyValue({
      variables: {
        data: {
          id: actionId,
          value: values?.envKeyValue,
        },
      },
    });
    setIsEditable(false);
    setVisible(false);
    setActionId(null);
  };

  return (
    <Modal
      open={visible}
      centered
      title="Private key value"
      onCancel={() => setVisible(false)}
      footer={[
        <div key="footer" className="d-flex gap-8 justify-end">
          <Button onClick={() => setVisible(false)}>Cancel</Button>
          <Button
            type="primary"
            onClick={() =>
              !isEditable ? setIsEditable(true) : privateKeyValueform?.submit()
            }
          >
            {!isEditable ? 'Edit' : 'Save'}
          </Button>
        </div>,
      ]}
    >
      <Form form={privateKeyValueform} layout="vertical" onFinish={onFinish}>
        <Form.Item name="envKeyValue">
          <Input disabled={!isEditable} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
