import { NetworkStatus } from '@apollo/client/core';
import { useLazyQuery } from '@apollo/client/react/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useRefValue from './useRefValue';

const LIMIT = 10;

const useInfiniteQuery = (query, options) => {
  const {
    limit = LIMIT,
    skip,
    initialData,
    filters,
    onError,
    onCompleted,
    ...rest
  } = options;
  const countSelector = useRefValue(options.countSelector);
  const dataSelector = useRefValue(options.dataSelector);
  const variableSelector$ = useRefValue(options.variableSelector);
  const hasInitialData = Boolean(initialData);

  const [data, setData] = useState(() =>
    initialData ? dataSelector.current(initialData) : [],
  );

  const [networkStatus, setNetworkStatus] = useState(() =>
    skip || hasInitialData ? NetworkStatus.ready : NetworkStatus.loading,
  );

  const [count, setCount] = useState(() =>
    initialData ? countSelector.current(initialData) : 0,
  );

  const [fetchData] = useLazyQuery(query, {
    onCompleted(res) {
      setNetworkStatus(NetworkStatus.ready);
      onCompleted?.(res);
    },
    onError(error) {
      setNetworkStatus(NetworkStatus.ready);
      onError?.(error);
    },
    ...rest,
  });

  const loading = [NetworkStatus.loading, NetworkStatus.refetch].includes(
    networkStatus,
  );
  const offset = data.length;

  const loadingMore = networkStatus === NetworkStatus.fetchMore;

  const hasMore = data.length < count;

  const isFetching = loading || loadingMore;

  const variableSelector = useCallback(
    (option) => {
      const { filters: filter$ } = option;
      return variableSelector$.current({ ...option, filters: filter$ });
    },
    [variableSelector$],
  );

  useEffect(() => {
    if (initialData) {
      setData(dataSelector.current(initialData));
      setCount(countSelector.current(initialData));
      setNetworkStatus(NetworkStatus.ready);
    }
  }, [initialData, dataSelector, countSelector]);

  useEffect(() => {
    if (skip || hasInitialData) return;

    (async () => {
      try {
        setNetworkStatus(NetworkStatus.loading);
        const { data: fetchedData } = await fetchData({
          variables: variableSelector({
            skip: 0,
            limit,
            filters,
          }),
        });
        if (fetchedData) {
          setData(dataSelector.current(fetchedData));
          setCount(countSelector.current(fetchedData));
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error, 'err');
      }
    })();
  }, [
    skip,
    fetchData,
    limit,
    hasInitialData,
    filters,
    variableSelector,
    dataSelector,
    countSelector,
  ]);

  const refetch = useCallback(
    async (selector) => {
      try {
        const filters$ = filters;
        const variables = (selector ?? variableSelector)({
          skip: 0,
          limit,
          filters: filters$,
        });
        setNetworkStatus(NetworkStatus.refetch);
        const { data: fetchedData } = await fetchData({ variables });
        if (fetchedData) {
          setData(dataSelector.current(fetchedData));
          setCount(countSelector.current(fetchedData));
        }
      } catch {
        //
      }
    },
    [fetchData, filters, limit, variableSelector, dataSelector, countSelector],
  );

  const fetchMore = useCallback(async () => {
    if (!hasMore) return;
    try {
      setNetworkStatus(NetworkStatus.fetchMore);
      const { data: fetchedData } = await fetchData({
        variables: variableSelector({ skip: offset, limit, filters }),
      });
      if (fetchedData) {
        setData((prev) => [...prev, ...dataSelector.current(fetchedData)]);
        setCount(countSelector.current(fetchedData));
      }
    } catch {
      //
    }
  }, [
    offset,
    filters,
    fetchData,
    limit,
    hasMore,
    variableSelector,
    dataSelector,
    countSelector,
  ]);

  const addItem = useCallback((item, at = 'start') => {
    setData((prev) => {
      if (at === 'start') return [item, ...prev];
      return [...prev, item];
    });
    setCount((prev) => prev + 1);
  }, []);

  const updateItems = useCallback((condition, updatedData) => {
    setData((prev) =>
      prev.map((item) => {
        if (condition(item)) {
          return {
            ...item,
            ...updatedData,
          };
        }
        return item;
      }),
    );
  }, []);

  const removeItems = useCallback(
    (condition) => {
      let totalItemsRemoved = 0;
      setData((prev) => {
        const filteredItems = prev.filter((item) => !condition(item));
        totalItemsRemoved = offset - filteredItems.length;
        return filteredItems;
      });

      setCount((prev) => prev - totalItemsRemoved);
    },
    [offset],
  );

  return useMemo(
    () => ({
      data,
      count,
      loading,
      loadingMore,
      isFetching,
      hasMore,
      refetch,
      fetchMore,
      addItem,
      updateItems,
      removeItems,
    }),
    [
      data,
      count,
      loading,
      loadingMore,
      hasMore,
      refetch,
      fetchMore,
      addItem,
      updateItems,
      removeItems,
      isFetching,
    ],
  );
};

export default useInfiniteQuery;
