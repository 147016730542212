import { useMutation } from '@apollo/client';
import { Form, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { DUPLICATE_ENV_KEY_VALUE } from '../graphql/Mutations';

export default function DuplicateKeyValueModal({
  visible,
  setVisible,
  envRecords = [],
  selectedKeys = [],
  refetchEnvKeyValueData,
  selectedRepositories,
  setSelectedRowKeys,
  sourceType,
  selectedRepositorieData,
}) {
  const [form] = Form.useForm();
  const [sourceBranch, setSourceBranch] = useState(null);

  const targetOptions = envRecords?.filter((env) => env?.id !== sourceBranch);

  const [duplicateValue] = useMutation(DUPLICATE_ENV_KEY_VALUE, {
    onCompleted() {
      refetchEnvKeyValueData({
        variables: {
          data: {
            refId:
              sourceType === 'GITHUB'
                ? selectedRepositorieData?.repositorieData?.name
                : selectedRepositories,
          },
        },
      });
      setSelectedRowKeys([]);
      setVisible(false);
      form?.resetFields();
    },
    onError() {},
  });

  const onFinish = (values) => {
    const { sourceId, targetIds } = values;
    duplicateValue({
      variables: {
        data: {
          keyIds: selectedKeys,
          sourceId,
          targetIds,
        },
      },
    });
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event?.key === 'Enter' && visible) {
        event?.preventDefault();
        form?.submit();
      }
    };

    // eslint-disable-next-line no-undef
    document?.addEventListener('keydown', handleKeyDown);
    return () => {
      // eslint-disable-next-line no-undef
      document?.removeEventListener('keydown', handleKeyDown);
    };
  }, [visible]);

  return (
    <Modal
      open={visible}
      centered
      title="Copy value to another ENV"
      onCancel={() => {
        setVisible(false);
        setSelectedRowKeys([]);
        form?.resetFields();
      }}
      onOk={() => form?.submit()}
      okText="Copy"
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Source Env"
          name="sourceId"
          rules={[
            {
              required: true,
              message: 'Please enter key name',
            },
          ]}
        >
          <Select
            className="selection"
            placeholder="Select Source Env"
            options={envRecords?.map((env) => ({
              label: env?.name,
              value: env?.id,
            }))}
            onSelect={(value) => setSourceBranch(value)}
          />
        </Form.Item>
        <Form.Item
          label="Target Env"
          name="targetIds"
          rules={[
            {
              required: true,
              message: 'Please enter key name',
            },
          ]}
        >
          <Select
            className="selection"
            placeholder="Select Target Env"
            mode="multiple"
            options={targetOptions?.map((env) => ({
              label: env?.name,
              value: env?.id,
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
