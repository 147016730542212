import { useLayoutEffect, useRef } from 'react';

const useRefValue = (value) => {
  const ref = useRef(value);

  useLayoutEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
};

export default useRefValue;
