import { Button, Divider, Layout } from 'antd';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
  BREAKPOINTS,
  PROJECT_DATA,
  REPOSITORY_DATA,
  ROUTES,
  SOURCE_TYPE,
} from '../common/constants';
import MobileLogoComponent from '../components/MobileLogoComponent';
import useRouter from '../hooks/useRouter';
import './App.css';
import AppHeader from './components/header/AppHeader';
import UserProfile from './components/header/UserProfile';

const { Content } = Layout;
const App = () => {
  const { navigate } = useRouter();
  const [isDesktop, setDesktop] = useState(
    // eslint-disable-next-line no-undef
    window.innerWidth > BREAKPOINTS.tablet,
  );
  const [isActive, setActive] = useState(false);

  const handleOverlay = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    const updateMedia = () => {
      // eslint-disable-next-line no-undef
      if (window.innerWidth > BREAKPOINTS.tablet) {
        setDesktop(true);
      } else {
        setDesktop(false);
      }
    };
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', updateMedia);
    // eslint-disable-next-line no-undef
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <Layout hasSider>
      {!isDesktop && (
        <span
          className={isActive ? 'active overlay-responsive' : 'overlay-disable'}
          onClick={handleOverlay}
        />
      )}
      <Layout className="site-layout">
        <AppHeader>
          <div className="header-wrapper">
            <div className="d-flex gap-8 align-center">
              <Button
                id="logo"
                type="link"
                className="m-0 p-0"
                onClick={() => {
                  // eslint-disable-next-line no-undef
                  localStorage?.removeItem(PROJECT_DATA);
                  // eslint-disable-next-line no-undef
                  localStorage?.removeItem(REPOSITORY_DATA);
                  // eslint-disable-next-line no-undef
                  localStorage?.removeItem(SOURCE_TYPE);
                  navigate(ROUTES.MAIN);
                  // eslint-disable-next-line no-undef
                  window.location.reload();
                }}
              >
                <MobileLogoComponent className="mr-8" />
                LW Vault
              </Button>
              <Divider type="vertical" />
              <div id="header-title-content" />
            </div>
            <div className="d-flex align-center gap-16">
              <UserProfile />
            </div>
          </div>
        </AppHeader>
        <Content className="wrapper">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
