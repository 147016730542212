import { useMutation } from '@apollo/client';
import { Checkbox, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { REGEX } from '../../../common/constants';
import { ADD_ENV_KEYS } from '../graphql/Mutations';

export default function AddKeyModal({
  visible,
  setVisible,
  repoId,
  refetchEnvKeyValueData,
  selectedRepositories,
  sourceType,
  selectedRepositorieData,
}) {
  const [form] = Form.useForm();
  const [isPrivate, setIsPrivate] = useState(false);

  const [addEnvKey] = useMutation(ADD_ENV_KEYS, {
    onCompleted() {
      refetchEnvKeyValueData({
        variables: {
          data: {
            refId:
              sourceType === 'GITHUB'
                ? selectedRepositorieData?.repositorieData?.name
                : selectedRepositories,
          },
        },
      });
      form?.resetFields();
      setVisible(false);
      setIsPrivate(false);
    },
    onError() {},
  });

  const onFinish = (values) => {
    addEnvKey({
      variables: {
        data: {
          ...values,
          repositoryId: repoId,
          isPrivate,
        },
      },
    });
  };

  const onChange = (e) => {
    setIsPrivate(e?.target?.checked);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event?.key === 'Enter' && visible) {
        event?.preventDefault();
        form?.submit();
      }
    };

    // eslint-disable-next-line no-undef
    document?.addEventListener('keydown', handleKeyDown);
    return () => {
      // eslint-disable-next-line no-undef
      document?.removeEventListener('keydown', handleKeyDown);
    };
  }, [visible]);

  return (
    <Modal
      open={visible}
      centered
      title="Create a new key"
      onOk={() => {
        form?.submit();
      }}
      onCancel={() => {
        setVisible(false);
        form?.resetFields();
      }}
      okText="Add"
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Key Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please enter key name',
            },
            {
              pattern: REGEX?.CAPITAL_LETTER_AND_NUMBER_WITH_NO_BLANK_SPACE,
              message:
                'Key must only contain uppercase letters and underscores, with no spaces',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Key Value" name="value">
          <Input />
        </Form.Item>
        <Form.Item label="Key Type" name="isPrivate" valuePropName="checked">
          <Checkbox onChange={onChange} checked={isPrivate}>
            Is Private
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
}
