import { Select } from 'antd';
import debounce from 'lodash/debounce';
import React, { useMemo, useState } from 'react';
import useInfiniteQuery from '../hooks/useInfiniteQuery';

const LIMIT = 10;

const InfiniteSelect = (props) => {
  const [search, setSearch] = useState('');

  const {
    query,
    countSelector,
    dataSelector,
    variableSelector: variableSelector$,
    client,
    context,
    filters,
    fetchPolicy,
    initialData,
    limit = LIMIT,
    onCompleted,
    onError,
    skip,
    loading: loadingInput,
    onPopupScroll,
    allowSearch,
    onSearch,
    extraOptions,
    filterOptions,
    onSelect,
    ...rest
  } = props;

  const variableSelector = ({ skip: skip$, limit: limit$ }) => {
    const filters$ = filters;
    return allowSearch
      ? variableSelector$({
          skip: skip$,
          limit: limit$,
          search,
          filters: filters$,
        })
      : variableSelector$({ skip: skip$, limit: limit$, filters: filters$ });
  };

  const {
    data,
    loading,
    loadingMore,
    fetchMore,
    hasMore,
    refetch,
  } = useInfiniteQuery(query, {
    countSelector,
    variableSelector,
    dataSelector,
    client,
    context,
    fetchPolicy,
    filters,
    initialData,
    limit,
    onCompleted,
    onError,
    skip,
  });

  const options = useMemo(() => {
    let newData = [...data];

    if (extraOptions && extraOptions.length > 0) {
      newData = [...extraOptions, ...newData];
    }

    if (filterOptions) {
      newData = newData.filter(filterOptions);
    }

    return newData;
  }, [data, filterOptions, extraOptions]);

  const debounceRefetch = useMemo(() => debounce(refetch, 500), [refetch]);

  const isLoading = loading || loadingMore || loadingInput;

  const handleScroll = (e) => {
    const { currentTarget } = e;
    onPopupScroll?.(e);
    if (!isLoading && hasMore) {
      if (
        currentTarget.scrollTop + currentTarget.offsetHeight >=
        currentTarget.scrollHeight
      ) {
        fetchMore();
      }
    }
  };

  const handleSearch = (value) => {
    onSearch?.(value);
    if (allowSearch) {
      setSearch(value);
      debounceRefetch(({ skip: skip$, limit: limit$, filters: filters$ }) =>
        variableSelector$({
          skip: skip$,
          limit: limit$,
          search: value,
          filters: filters$,
        }),
      );
    }
  };

  return (
    <Select
      loading={isLoading}
      {...(allowSearch && {
        showSearch: true,
        onSearch: handleSearch,
      })}
      onPopupScroll={handleScroll}
      onDropdownVisibleChange={(open) => {
        if (!open) handleSearch('');
      }}
      {...rest}
      options={options}
      filterOption={false}
      labelInValue
      onSelect={onSelect}
    />
  );
};

export default InfiniteSelect;
