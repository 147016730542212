import { PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Select } from 'antd';
import { differenceBy, map, omit, trim } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import {
  PROJECT_DATA,
  REPOSITORY_DATA,
  ROUTES,
  SOURCE_TYPE,
} from '../../../common/constants';
import InfiniteSelect from '../../../components/InfiniteSelect';
import useRouter from '../../../hooks/useRouter';
import { ADD_ENV } from '../graphql/Mutations';
import {
  GET_BRANCHES,
  GET_PROJECTS,
  GET_REPOSITORIES,
} from '../graphql/Queries';

export default function ProjectBranchSelection({
  selectedRepositories,
  setSelectedRepositories,
  refetchEnvKeyValueData,
  repoEnvs,
  sourceType,
  setSourceType,
  selectedRepositorieData,
  setSelectedRepositorieData,
  setRepoId,
  setSelectedProject,
  selectedProject,
}) {
  const {
    navigate,
    location: { state },
  } = useRouter();
  const { setRepositoryId } = useContext(AppContext);

  const [repositories, setRepositories] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const [fetchRepos, { loading: repositoriesLoading }] = useLazyQuery(
    GET_REPOSITORIES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        setRepositories(res?.gitRepositories?.data?.repositories);
        // eslint-disable-next-line no-undef
        localStorage?.setItem(
          SOURCE_TYPE,
          res?.gitRepositories?.data?.sourceType,
        );
        setSourceType(res?.gitRepositories?.data?.sourceType);
      },
      onError: () => {},
    },
  );

  const [fetchBranch, { loading: branchesLoading }] = useLazyQuery(
    GET_BRANCHES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const filteredBranches = differenceBy(
          res?.gitEnvironments?.data,
          repoEnvs,
          'name',
        );
        setBranchList(filteredBranches);
      },
      onError: () => {},
    },
  );

  const [addEnv] = useMutation(ADD_ENV, {
    onCompleted() {
      refetchEnvKeyValueData({
        variables: {
          data: {
            refId:
              sourceType === 'GITHUB'
                ? selectedRepositorieData?.repositorieData?.name
                : selectedRepositories,
          },
        },
      });
      setSelectedBranch(null);
      fetchBranch({
        variables: {
          where: {
            id: selectedRepositories,
            name: selectedRepositorieData?.repositorieData?.name,
            owner: selectedRepositorieData?.repositorieData?.owner ?? '',
            sourceType,
          },
        },
      });
    },
    onError() {},
  });

  useEffect(() => {
    if (state?.repositoryRefId) {
      setSelectedRepositories(state?.repositoryRefId);
    }
  }, [state]);

  useEffect(() => {
    if (selectedProject) {
      fetchRepos({
        variables: {
          where: {
            integrations: map(
              selectedProject?.projectData?.integrations,
              (item) => omit(item, '__typename'),
            ),
          },
        },
      });
    }
    if (selectedRepositories) {
      fetchBranch({
        variables: {
          where: {
            id: selectedRepositories,
            name: selectedRepositorieData?.repositorieData?.name,
            owner: selectedRepositorieData?.repositorieData?.owner ?? '',
            sourceType,
          },
        },
      });
    }
  }, [selectedProject, selectedRepositories]);

  const handleAddEnv = (branchName) => {
    addEnv({
      variables: {
        data: {
          envName: branchName,
          projectRefId: trim(selectedProject?.value),
          repositoryRefId:
            sourceType === 'GITHUB'
              ? selectedRepositorieData?.repositorieData?.name
              : selectedRepositories,
          sourceType,
        },
      },
    });
  };

  const handleProjectClear = () => {
    navigate(ROUTES.MAIN);
    // eslint-disable-next-line no-undef
    window.location.reload();
    // eslint-disable-next-line no-undef
    localStorage?.removeItem(PROJECT_DATA);
    // eslint-disable-next-line no-undef
    localStorage?.removeItem(REPOSITORY_DATA);
    // eslint-disable-next-line no-undef
    localStorage?.removeItem(SOURCE_TYPE);
    setSelectedProject(null);
    setSelectedRepositories(null);
    setSelectedBranch(null);
    setRepositoryId(null);
    setRepoId(null);
  };

  return (
    <>
      <div className="d-flex gap-8">
        <InfiniteSelect
          allowSearch
          allowClear
          fetchPolicy="no-cache"
          query={GET_PROJECTS}
          variableSelector={({ skip, limit, search }) => ({
            data: { skip, limit, search },
          })}
          countSelector={(data) => data.gitProjects?.count ?? 0}
          dataSelector={(data) =>
            data.gitProjects?.data?.map((project) => ({
              label: project?.name ?? '',
              value: project?.integrations?.[0]?.referenceId ?? '',
              projectData: project,
            })) ?? []
          }
          placeholder="Select Project"
          className="selection"
          onClear={handleProjectClear}
          onChange={(project, projectData) => {
            setSelectedProject(projectData);
            setSelectedRepositories(null);
            setSelectedBranch(null);
            setRepositoryId(null);
            setRepoId(null);
            if (projectData) {
              // eslint-disable-next-line no-undef
              localStorage?.setItem(PROJECT_DATA, JSON?.stringify(projectData));
            }
          }}
          value={selectedProject?.project || state?.projectData?.value}
        />
        <Select
          placeholder="Select Repository"
          className="repo-selection"
          disabled={!selectedProject}
          options={repositories?.map((repo) => ({
            value: repo?.id,
            label: repo?.name,
            repositorieData: repo,
          }))}
          loading={repositoriesLoading}
          value={!repositoriesLoading ? selectedRepositories : null}
          onSelect={(value, repoData) => {
            setSelectedRepositorieData(repoData);
            setSelectedRepositories(value);
            setSelectedBranch(null);
            // eslint-disable-next-line no-undef
            localStorage?.setItem(REPOSITORY_DATA, JSON?.stringify(repoData));
          }}
        />
        <Select
          className="selection"
          disabled={!selectedRepositories || branchList?.length <= 0}
          options={branchList?.map((branch) => ({
            value: branch?.name,
            label: branch?.name,
          }))}
          value={selectedBranch}
          loading={branchesLoading}
          placeholder="Select ENV"
          onSelect={(value) => {
            setSelectedBranch(value);
          }}
        />
        <Button
          disabled={!selectedBranch}
          onClick={() => handleAddEnv(selectedBranch)}
          type="primary"
        >
          <PlusOutlined /> Add Env.
        </Button>
      </div>
    </>
  );
}
